import Api from "../../services/Api";

const api = new Api();

export default (service, data, translations) => (dispatch) => {
  const body = {};
  
  for (let i = 0; i < service.fields.length; i++) {
    let field = service.fields[i];
    if (field.type === "auto") {
      if(typeof field.input !== 'undefined'){
        if(!field.input) continue;
      }
      body[field.name] = data[field.name] ? data[field.name] : 
        typeof field.value === "function" ? field.value(data) : field.value;
    } else {
      body[field.name] = data[field.name];
    }
    
  }
 
  body.translations = translations;
  dispatch({
    type: "CRUD_ADD",
    data: data,
    payload: null,
  });

  api
    .crud({
      endpoint:
        typeof service.insertEndpoint !== "undefined"
          ?  typeof service.insertEndpoint === 'function' ? 
          service.insertEndpoint(data) 
          : service.insertEndpoint
          : service.endpoint,
      actionType: "ADD",
      data: body,
    })
    .then((response) => {
      if (typeof response.data !== "undefined") {
        dispatch({
          type: "CRUD_ADD_SUCCESS",
          payload: response,
        });
      } else {
        dispatch({
          type: "CRUD_ADD_ERROR",
          payload: null,
        });
      }
    });
};
