const API_URL = process.env.REACT_APP_API_URI;
const qs = require("qs");
const XMLHttpRequest = require("xmlhttprequest").XMLHttpRequest;
export default class Api {
  async post(url, data, withToken, custom_uri) {
    const headers = withToken
      ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      : {
          "Content-Type": "application/json",
        };
    let response = await fetch(custom_uri ? custom_uri + url : API_URL + url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
    return response;
  }

  async get(url, data, withToken, custom_uri) {
  
    const headers = withToken
      ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      : {
          "Content-Type": "application/json",
        };
    try{
      let response = await fetch(custom_uri ? custom_uri + url : API_URL + url + "?" + qs.stringify(data), {
        headers: headers,
      })
        .then((response) => {
          console.log({response})
          return response.json();
        })
        .then((result) => {
          return result;
        }).catch(err=>{
          console.log({err})
          return {
            status:0,
            error: err.message
          }
        });
        return response;
    }catch(err){
     
      if(err.message === "Invalid Credentials"){
        return {
          status:403,
          error:"Your session has expired!"
        }
      }
      return {
        status:0,
        error: err.message
      };
    }
    
   
  }

  async delete(url, data, withToken) {
    const headers = withToken
      ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      : {
          "Content-Type": "application/json",
        };

    let response = await fetch(API_URL + url, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
    return response;
  }

  login(data) {
    return this.post("/login", data);
  }
  async modules() {
    let response = await fetch(API_URL + "/platform/modules", {})
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
    return response;
  }
  register(data) {
    return this.post("/register", data);
  }
  questions(data) {
    return this.get("/questions", data, true);
  }
  result(data) {
    return this.post("/result", data, true);
  }
  async upload(url, data, withToken, custom_uri) {
    const headers = withToken
      ? {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      : {};

    const formData = new FormData();
    for (let k in data) {
      formData.append(k, data[k]);
    }
    let response = await fetch(custom_uri ? custom_uri + url : API_URL + url, {
      method: "POST",
      body: formData,
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
    return response;
  }
  /*//this upload will use the xhr so we can track the progress and make it resumable
  async upload2(url, data, withToken, custom_uri, uploadId) {
    
   
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    const statusApi = `/media/status/${uploadId}`;
    let status = await this.get(statusApi, {}, withToken, custom_uri);
    if(status.status === 0){
      throw new Error('unable to connect to server!');
    }
    let startByte = status.startByte ? status.startByte : 0;

    for (let k in data) {
      formData.append(k, data[k]);
    }
    const headers = withToken
      ? {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      : {};
      
      xhr.open("POST", "upload", true);

      // File id, so that the server knows which file we upload
      xhr.setRequestHeader('X-File-Id', fileId);
      
      // The byte we're resuming from, so the server knows we're resuming
      xhr.setRequestHeader('X-Start-Byte', startByte);
      
      xhr.upload.onprogress = (e) => {
        console.log(`Uploaded ${startByte + e.loaded} of ${startByte + e.total}`);
      };
      
      // file can be from input.files[0] or another source
      xhr.send(file.slice(startByte));
     /* 
    let response = await fetch(custom_uri ? custom_uri + url : API_URL + url, {
      method: "POST",
      body: formData,
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        return result;
      });
      

    return response;
  }*/
  async call(opts, withToken) {
    
    let { endpoint, data, scenario } = opts;
    let body = data;
    scenario = scenario ? scenario.toLowerCase() : 'get';
    

    switch (scenario) {
      case "pagination":
        return this.get(endpoint, body, withToken);
        break;
      case "read":
        return this.get(endpoint + "/" + id, body, withToken);
        break;
      case "insert":
        return this.post(endpoint, body, withToken);
        break;
      case "update":
        let id = data && typeof data.id !== "undefined" ? data.id : null;
        if (id !== null) delete body.id;
        return this.post(endpoint + "/" + id, body, withToken);
        break;
      case "delete":
        return this.delete(endpoint + "/" + id, body, withToken);
        break;
      case "post":
        return this.post(endpoint, body, withToken);
        break;

      case "upload":
        return this.upload(endpoint, body, withToken);
        break;
      default:
        return this.get(endpoint, body, withToken);
        break;
    }
  }
  crud(opts) {
    const { endpoint, actionType, id, data } = opts;

    switch (actionType) {
      case "ADD":
        return this.post(endpoint, data, true);
        break;
      case "UPDATE":
        return this.post(endpoint + "/" + id, data, true);
        break;
      case "DELETE":
        return this.delete(endpoint + "/" + id, {}, true);
        break;
      case "GET":
        return this.get(endpoint + "/" + id, {}, true);
        break;
      case "LOOKUP":
        return this.get(endpoint + "/lookup", data, true);
        break;
        
      default:
        return this.get(endpoint, data, true);
        break;
    }
  }
  do_action(opts) {
    const { service, actionName, id } = opts;

    return this.get(
      service.endpoint + "/" + actionName.toLowerCase() + "/" + id,
      {},
      true
    );
  }
}
