import Api from "../../services/Api";

const api = new Api();

//this would be a single action but can be called to many components
//so the payload must be splitted by the component's name
const queue = [];
let busy = false;

const execute = (dispatch) => {
  if (busy) {
    setTimeout(() => {
      execute(dispatch);
    }, 1000);
    return;
  }

  if (queue.length > 0) {
    const { state, name, opts } = queue.shift();
    busy = true;
    let _state = {
      ...state,
      type: "CALL_ACTION",
      data: opts,
      fetching: true,
    };
    _state[name] = null;

    dispatch(_state);

    api.call(opts, true).then((response) => {
      if (typeof response === "undefined") {
        let new_state = {
          ...state,
          type: "CALL_ACTION_ERROR",
        };
        new_state[name] = response;
        dispatch(new_state);
        busy = false;
        return;
      }
      if (response.status === 1) {
        let new_state = {
          ...state,
          type: "CALL_ACTION_SUCCESS",
        };
        new_state[name] = response;
        dispatch(new_state);
        busy = false;
        return;
      } else if (response.status === 403) {
        let new_state = {
          ...state,
          type: "CALL_ACTION_ERROR",
        };
        new_state[name] = response;
        dispatch(new_state);
        document.location = "/login";
        busy = false;
        return;
      } else {
        let new_state = {
          ...state,
          type: "CALL_ACTION_ERROR",
        };
        new_state[name] = response;
        dispatch(new_state);
        busy = false;
        return;
      }
    });
  }
};
export default (state, name, opts) => (dispatch) => {
  queue.push({ state, name, opts });
  execute(dispatch);
};
