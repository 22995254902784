const text = (name, content) => {
  if (typeof content === "undefined") return;
  if (typeof content.payload === "undefined") return;
  if (content.payload === null) return;
  if (typeof content.payload.data === "undefined") return;

  let data = content.payload.data;

  for (let k in data) {
    if (data[k].name.localeCompare(name) === 0) return data[k].txt;
  }
};
const showMessage = (instance, text) => {
  instance.setState({
    snackbar:true,
    snackbarText: text
  });
}
const mustHaveValue = (obj) => {
  if(typeof obj === 'undefined') return;
  if(obj === null) return;
  return obj;
}
const hasValue = (obj) => {
  if(typeof obj === 'undefined') return;
  if(obj === null) return;
  return true;
}
const getValues = (props, key) => {
  if (typeof props === "undefined") return [];
  if (typeof props.payload === "undefined") return [];
  if (props.payload === null) return [];
  if (props.payload[key] === null) return [];
  return props.payload[key];
};

const getPayload = props => {
  if (typeof props === "undefined") return;
  if (typeof props.payload === "undefined") return;
  if (props.payload === null) return;

  return props.payload;
};

const userHasAccess = access => {
  let roles = localStorage.getItem("roles");
  
  if (!roles) return;
  if(typeof roles === 'string') roles = JSON.parse(roles);
  if (roles.length === 0) return;
  
  for (let k in access) {
    if (roles.includes(access[k])) return true;
  }
  return false;
};

const session = ()=>{
  
  let user_id = localStorage.getItem("user_id");

  
  let groups = localStorage.getItem("groups") ? JSON.parse(localStorage.getItem("groups")) : [];
  let managed_groups = localStorage.getItem("managed_groups") ? JSON.parse(localStorage.getItem("managed_groups")) : [];
  let company = localStorage.getItem("company") !=='undefined' ? JSON.parse(localStorage.getItem("company")) : [];
  let team_id = localStorage.getItem("team_id");
  let member_id = localStorage.getItem("member_id");
  let roles = localStorage.getItem("roles");
  return {
    groups,
    managed_groups,
    company,
    team_id,
    member_id,
    user_id,
    roles
  }
  
}
export {
  text,
  getValues,
  getPayload,
  userHasAccess,
  mustHaveValue,
  showMessage,
  hasValue,
  session
};

export default {
    text,
  getValues,
  getPayload,
  userHasAccess,
  mustHaveValue,
  showMessage,
  session
}