import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        redirect: false,
      };
     
    }
    componentDidMount() {
     
      let token = localStorage.getItem("token");
      
      if(typeof token === 'undefined') {
        
        this.setState({
            loading:false,
            redirect:true
        });
        return;
      } else if(token === null){
          this.setState({
              loading:false,
              redirect:true
          })
      }else{
      
        this.setState({
            loading:false,
            redirect:false
        })
      }
    }
    render() {
      const {route} = this.props;
      const { loading, redirect } = this.state;
     
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to="/login" />;
      }
      
      return <ComponentToProtect {...this.props} />;
    }
  }
}