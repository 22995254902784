import "../fake-db";
import React, { Suspense } from "react";
import "../styles/app/app.scss";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";

import routes from "./routes";
import configureStore from "./redux/Store";
import { renderRoutes } from "react-router-config";
//import Auth from "./auth/Auth";
import RootRoutes from "./routes";
import { Loading } from "@gull";
//import configureStore from "./store";
import { PersistGate } from "redux-persist/integration/react";

let { store, persistor } = configureStore();

function App() {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loading></Loading>}>
            <Router history={history}>{renderRoutes(RootRoutes)}</Router>
          </Suspense>
        </PersistGate>
      </Provider>
    </AppContext.Provider>
  );
}
export default App;
