import Api from "../../services/Api";

const api = new Api();

export default (service, id, data, translations) => (dispatch) => {
 
  const body = {};
  for (let i = 0; i < service.fields.length; i++) {
    let field = service.fields[i];
   
    body[field.name] = data[field.name];
  }
  body.translations = translations;

  // if(typeof data.rowNumber !== 'undefined') delete data.rowNumber;

  dispatch({
    type: "CRUD_UPDATE",
    data: body,
    payload: null,
  });

  api
    .crud({
      endpoint:
        typeof service.updateEndpoint !== "undefined"
          ? typeof service.updateEndpoint === "function"
            ? service.updateEndpoint(body)
            : service.updateEndpoint
          : service.endpoint,
      actionType: "UPDATE",
      id,
      data:body,
    })
    .then((response) => {
      if (typeof response.data !== "undefined") {
        dispatch({
          type: "CRUD_UPDATE_SUCCESS",
          payload: response,
        });
      } else {
        dispatch({
          type: "CRUD_UPDATE_ERROR",
          payload: null,
        });
      }
    });
};
