import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import CreateStore from "./CreateStore";
import CrudStore from "./CrudStore";
import crud from "./crud";
import crud_add from "./crud_add";
import crud_delete from "./crud_delete";
import crud_get from "./crud_get";
import crud_update from "./crud_update";
import do_action from "./do_action";
import call_action from "./call_action";

//auto-create CRUD actions / reducers
const profile = CrudStore("profile","/profile");
const courses = CrudStore("courses","/courses");


const preferred = CrudStore("preferred","/preferred");
const report = CrudStore("report","/report");
const invoice = CrudStore("invoice","/invoice");

//auto create custom actions / reducers
const [login, loginAction] = CreateStore("LOGIN","/login","post");
const [register, registerAction] = CreateStore("REGISTER","/register","post");
const [refresh_account, refreshAccountAction] = CreateStore("REFRESH_ACCOUNT", "/me", "get", true);

const [content, getContentAction] = CreateStore("CONTENT", "/content", "get");
const [daerah_login_daily, getDaerahLoginDailyAction] = CreateStore("DAERAH_LOGIN_DAILY", "/summary/login", "get", true);
const [inovasi_covid_total, getInovasiCovidTotalAction] = CreateStore("INOVASI_COVID", "/summary/covid", "get", true);


/*const [content, getContentAction] = CreateStore("CONTENT","/content","get");
const [buy, buyAction] = CreateStore("BUY","/buy","post",true);
const [sell, sellAction] = CreateStore("SELL","/sell","post", true);
const [upcoming_courses, upcomingCoursesAction] = CreateStore("UPCOMING_COURSE","/course/available","pagination",true);
const [course_request, courseRequestAction] = CreateStore("COURSE_REQUEST","/course/request","pagination",true);
const [taken_courses, takenCoursesAction] = CreateStore("TAKEN_COURSE","/course/ongoing","pagination",true);
const [history, historyAction] = CreateStore("COURSE_HISTORY","/course/history","pagination",true);
*/
//const [summary, summaryAction] = CreateStore("SUMMARY","/summary","get",true);
//const laporan = CrudStore("LAPORAN","/laporan","post");

//const [upload, uploadAction] = CreateStore("UPLOAD","/media/upload","upload", true);


const [summary, summaryAction] = CreateStore(
  "SUMMARY",
  "/summary",
  "get",
  true
);
const [pemda_summary, pemdaSummaryAction] = CreateStore(
  "PEMDA_SUMMARY",
  (data)=>{
    return "/summary/" + data.pemda_id
  },
  "get",
  true
);


const [summary_covid, summaryCovidAction] = CreateStore(
  "SUMMARY",
  "/summary/covid/overall",
  "get",
  true
);
const [pemda_summary_covid, pemdaSummaryCovidAction] = CreateStore(
  "PEMDA_SUMMARY",
  (data)=>{
    return "/summary/covid/overall/" + data.pemda_id
  },
  "get",
  true
);

const [indikator_list, indikatorListAction] = CreateStore(
  "INDIKATOR_LIST",
  "/indikator",
  "pagination",
  true
);
const [setup_indikator, setupIndikatorAction] = CreateStore(
  "SETUP_INDIKATOR",
  data => {
    return "/daerah/" + data.id + "/indikator";
  },
  "pagination",
  true
);
const [update_indikator_daerah, updateIndikatorDaerahAction] = CreateStore(
  "UPDATE_INDIKATOR_DAERAH",
  data => {
    return "/daerah/" + data.id + "/indikator";
  },
  "post",
  true
);
//inisiatif
const [indikator_inovasi_list, indikatorInovasiListAction] = CreateStore(
  "INDIKATOR_INOVASI_LIST",
  "/indikator/inovasi",
  "pagination",
  true
);
const [setup_indikator_inovasi, setupIndikatorInovasiAction] = CreateStore(
  "SETUP_INDIKATOR_INOVASI",
  data => {
    return "/inovasi/" + data.id + "/indikator";
  },
  "pagination",
  true
);
const [update_indikator_inovasi, updateIndikatorInovasiAction] = CreateStore(
  "UPDATE_INDIKATOR_INOVASI",
  data => {
    return "/inovasi/" + data.id + "/indikator";
  },
  "post",
  true
);

//ujicoba
const [indikator_inovasi_list_ujicoba, indikatorInovasiListUjicobaAction] = CreateStore(
  "INDIKATOR_INOVASI_LIST_UJICOBA",
  "/indikator/inovasi/ujicoba",
  "pagination",
  true
);
const [setup_indikator_inovasi_ujicoba, setupIndikatorInovasiUjicobaAction] = CreateStore(
  "SETUP_INDIKATOR_INOVASI_UJICOBA",
  data => {
    return "/inovasi/" + data.id + "/indikator/ujicoba";
  },
  "pagination",
  true
);
const [update_indikator_inovasi_ujicoba, updateIndikatorInovasiUjicobaAction] = CreateStore(
  "UPDATE_INDIKATOR_INOVASI",
  data => {
    return "/inovasi/" + data.id + "/indikator/ujicoba";
  },
  "post",
  true
);

//-->
const [upload, uploadAction] = CreateStore(
  "UPLOAD",
  data => {
    return "/media/upload";
  },
  "upload",
  true
);

const [add_dokumen_daerah, addDokumenDaerahAction] = CreateStore(
  "ADD_DOKUMEN_DAERAH",
  data => {
    return "/daerah/" + data.id + "/dokumen";
  },
  "post",
  true
);

const [get_dokumen_daerah, getDokumenDaerahAction] = CreateStore(
  "GET_DOKUMEN_DAERAH",
  data => {
    return "/daerah/" + data.id + "/dokumen/" + data.indikator_id;
  },
  "get",
  true
);

const [delete_dokumen_daerah, deleteDokumenDaerahAction] = CreateStore(
  "DELETE_DOKUMEN_DAERAH",
  data => {
    return "/daerah/" + data.profil_id + "/dokumen";
  },
  "delete",
  true
);


//Inisiatif
const [add_dokumen_inovasi, addDokumenInovasiAction] = CreateStore(
  "ADD_DOKUMEN_INOVASI",
  data => {
    return "/inovasi/" + data.id + "/dokumen";
  },
  "post",
  true
);

const [get_dokumen_inovasi, getDokumenInovasiAction] = CreateStore(
  "GET_DOKUMEN_INOVASI",
  data => {
    return "/inovasi/" + data.id + "/dokumen/" + data.indikator_id;
  },
  "get",
  true
);

const [delete_dokumen_inovasi, deleteDokumenInovasiAction] = CreateStore(
  "DELETE_DOKUMEN_INOVASI",
  data => {
    return "/inovasi/" + data.profil_id + "/dokumen";
  },
  "delete",
  true
);

//ujicoba
const [add_dokumen_inovasi_ujicoba, addDokumenInovasiUjicobaAction] = CreateStore(
  "ADD_DOKUMEN_INOVASI_UJICOBA",
  data => {
    return "/inovasi/" + data.id + "/dokumen/ujicoba";
  },
  "post",
  true
);

const [get_dokumen_inovasi_ujicoba, getDokumenInovasiUjicobaAction] = CreateStore(
  "GET_DOKUMEN_INOVASI_UJICOBA",
  data => {
    return "/inovasi/" + data.id + "/dokumen/ujicoba/" + data.indikator_id;
  },
  "get",
  true
);

const [delete_dokumen_inovasi_ujicoba, deleteDokumenInovasiUjicobaAction] = CreateStore(
  "DELETE_DOKUMEN_INOVASI_UJICOBA",
  data => {
    return "/inovasi/" + data.profil_id + "/dokumen/ujicoba";
  },
  "delete",
  true
);

const [covid_summary, covidSummaryAction] = CreateStore(
  "COVID_SUMMARY",
  data => {
    return "/summary/covid/klaster";
  },
  "get",
  true
);
const [app_settings, appSettingAction] = CreateStore(
  "APP_SETTINGS",
  data => {
    return "/app_settings";
  },
  "get",
  true
);

//setup list of actions
const Actions = {
  loginAction,
  registerAction,
  summaryAction,
  uploadAction,
  refreshAccountAction,
 // ...laporan.actions,
  pemdaSummaryAction,
  summaryCovidAction,
  pemdaSummaryCovidAction,
  updateIndikatorDaerahAction,
  indikatorListAction,
  setupIndikatorAction,
  addDokumenDaerahAction,
  getDokumenDaerahAction,
  deleteDokumenDaerahAction,
  getInovasiCovidTotalAction,
 //inisiatif
  updateIndikatorInovasiAction,
  indikatorInovasiListAction,
  setupIndikatorInovasiAction,
  addDokumenInovasiAction,
  getDokumenInovasiAction,
  deleteDokumenInovasiAction,
  //ujicoba
  updateIndikatorInovasiUjicobaAction,
  indikatorInovasiListUjicobaAction,
  setupIndikatorInovasiUjicobaAction,
  addDokumenInovasiUjicobaAction,
  getDokumenInovasiUjicobaAction,
  deleteDokumenInovasiUjicobaAction,
  getDaerahLoginDailyAction,
  refreshAccountAction,
  covidSummaryAction,
  appSettingAction,
  ...profile.actions,
  ...courses.actions,
  ...preferred.actions,
  ...report.actions,
  ...invoice.actions
};

export { Actions };


const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  crud,
  crud_add,
  crud_delete,
  crud_update,
  crud_get,
  do_action,
  call_action,
  login,
  register,
  summary,
  upload,
  refresh_account,
  app_settings,
  summary_covid,
  inovasi_covid_total,
  pemda_summary,
  pemda_summary_covid,
  indikator_list,
  setup_indikator,
  get_dokumen_daerah,
  update_indikator_daerah,
  add_dokumen_daerah,
  delete_dokumen_daerah,
  //inisiatif
  indikator_inovasi_list,
  setup_indikator_inovasi,
  get_dokumen_inovasi,
  update_indikator_inovasi,
  add_dokumen_inovasi,
  delete_dokumen_inovasi,
  //ujicoba
  indikator_inovasi_list_ujicoba,
  setup_indikator_inovasi_ujicoba,
  get_dokumen_inovasi_ujicoba,
  update_indikator_inovasi_ujicoba,
  add_dokumen_inovasi_ujicoba,
  delete_dokumen_inovasi_ujicoba,
  daerah_login_daily,
  covid_summary,
  //-->
});

export default RootReducer;
