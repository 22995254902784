import React from "react";
import withAuth from "./withAuth";
import ModuleWrapper from "./ModuleWrapper";
import modules from "./modules";
import withGull from "./withGull";
import withGullLayout from "./withGullLayout";
import withDragDropContext from "./Components/withDnDContext";

const RegistrationContainer = React.lazy(() => import("./Components/RegistrationContainer"));
const ForgotPassword = React.lazy(() => import("./Components/ForgotPassword"));
const ChatSession = React.lazy(() => import("./Components/ChatSession"));
const ChatError = React.lazy(() => import("./Components/ChatError"));
const LoginContainer = React.lazy(() => import("./Components/LoginContainer"));
const ForgotContainer = React.lazy(() => import("./Components/ForgotContainer"));
const TaskLanding = React.lazy(() => import("./Components/TaskLanding"));

//const LogoutContainer = React.lazy(() => import("./Components/LogoutContainer"));
//const RegistrationContainer = React.lazy(() => import("./Components/RegistrationContainer"));

const ModuleContainer = React.lazy(() =>
  import("./Components/ModuleContainer")
);
const getModules = (prefix) => {
 
  let _routes = [];
  for(let i=0; i< modules.length; i++){
    let item = modules[i];
    if (item.divider){
      item.children.map((_item)=>{
         _routes.push({
              path: (prefix ? `${prefix}/` : "/") + _item.slug,
             
              component: _item.crud
                ? withAuth(ModuleWrapper(ModuleContainer, _item, modules))
                : withAuth(ModuleWrapper((_item.component), _item, modules)),
            });
      })
      continue;
    }
    _routes.push({
      path: (prefix ? `${prefix}/` : "/") + item.slug,
      exact: true,
      component: item.crud
        ? withAuth(ModuleWrapper(ModuleContainer, item, modules))
        : withAuth(ModuleWrapper((item.component), item, modules)),
    });
  }
  
  console.log({_routes});
  return _routes;
};
const getModulesByName = (slug) => {
  for (let k in modules) {
    if (modules[k].slug === slug) return modules[k];
  }
};
const routes = [
  {
    path: "/",
    exact: true,
    component: LoginContainer,
  },
  {
    path: "/login",
    exact: true,
    component: LoginContainer,
  },
  {
    path: "/logout",
    exact: true,
    component: LoginContainer,
  },
  {
    path: "/register",
    exact: true,
    component: ModuleWrapper(
      RegistrationContainer,
      getModulesByName("registration"),
      modules
    ),
  },
  {
    path: "/forgot",
    exact: true,
    component: ModuleWrapper(
      ForgotPassword,
      getModulesByName("forgot"),
      modules
    ),
  },
  {
    path: "/task/:id",
    exact: true,
    component: ModuleWrapper(
      TaskLanding,
    ),
  },
  
  {
    path: "/session",
    component: (withGull),
    routes: [
      {
        path: "/error",
        component: withAuth(ChatError)
    },
      {
        path: "/",
        component: withAuth(ChatSession)
    },
    
    ],
   
  },
 
  {
    path: "/dashboard",
    component: (withGull),
   
    routes: (getModules('/dashboard')),
  },
  /*{
    path: "/logout",
    exact: true,
    component: LogoutContainer
  },
  {
    path: "/register",
    exact: true,
    component: RegistrationContainer
  },
  
  {
    path: "/forgot",
    exact: true,
    component: ForgotContainer
  },
  {
    path: "/reset",
    exact: true,
    component: ResetContainer
  },*/
   //...getModules()
];

export default routes;
