import Api from "../../services/Api";


const api = new Api();

export default (service, id) => dispatch => {
    
    dispatch({
        type: 'CRUD_DELETE',
        data:{id},
        payload:null
    })
    
    api.crud({
        endpoint:  typeof service.deleteEndpoint !== "undefined"
        ?  typeof service.deleteEndpoint === 'function' ? 
        service.deleteEndpoint({id}) 
        : service.deleteEndpoint
        : service.endpoint,
        actionType: "DELETE",
        id
    }).then((response)=>{
        
        if(typeof response.data !== 'undefined'){
            dispatch({
                type:"CRUD_DELETE_SUCCESS",
                payload: response
            })
        }else{
            dispatch({
                type:"CRUD_DELETE_ERROR",
                payload:null,
            })
        }
    })
}