import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import RootReducer from "./reducers/RootReducer";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
}


const initialState = {};

const middlewares = [thunk];

const persistedReducer = persistReducer(persistConfig, RootReducer)


let devtools = x => x;

if (
  process.env.NODE_ENV !== "production" &&
  process.browser &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}


/*
export const Store = createStore(
  RootReducer,
  initialState,
  compose(applyMiddleware(...middlewares), devtools)
);
*/

export default () => {
  let store =  createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middlewares), devtools)
  );
  let persistor = persistStore(store)
  return { store, persistor }
}