import React from "react";

import { session } from "./libs/helper";
import moment from "moment-timezone";
import accounting from "accounting";
const DashboardContainer = React.lazy(() =>
  import("./Components/DashboardContainer")
);

const CoachingLanding = React.lazy(() =>
  import("./Components/CoachingLanding")
);
const TeamDashboardContainer = React.lazy(() =>
  import("./Components/TeamDashboardContainer")
);
const MemberDashboardContainer = React.lazy(() =>
  import("./Components/MemberDashboardContainer")
);
const CreateTaskContainer = React.lazy(() =>
  import("./Components/CreateTaskContainer")
);
const TeamsContainer = React.lazy(() => import("./Components/TeamsContainer"));
const QuestsContainer = React.lazy(() =>
  import("./Components/QuestsContainer")
);
const DelegateTaskContainer = React.lazy(() =>
  import("./Components/DelegateTaskContainer")
);
const ChatSession = React.lazy(() => import("./Components/ChatSession"));

const MyAccount = React.lazy(() => import("./Components/MyAccountContainer"));
const UserContainer = React.lazy(() => import("./Components/UserContainer"));
const UserMgmtContainer = React.lazy(() => import("./Components/UserMgmtContainer"));

const ImportMember = React.lazy(() => import("./Components/ImportMember"));

const CoachingProgrammeContainer = React.lazy(() =>
  import("./Components/CoachingProgrammeContainer")
);
const ReturnFromPayment = React.lazy(() =>
  import("./Components/ReturnFromPayment")
);
const RewardContainer = React.lazy(() =>
  import("./Components/RewardContainer")
);

const SubscriptionContainer = React.lazy(() =>
  import("./Components/SubscriptionContainer")
);
const WorkspaceContainer = React.lazy(() =>
  import("./Components/WorkspaceContainer")
);
const ConsultationContainer = React.lazy(() =>
  import("./Components/ConsultationContainer")
);
const BookConsultationContainer = React.lazy(() =>
  import("./Components/BookConsultationContainer")
);
const ConsultationSessionContainer = React.lazy(() =>
  import("./Components/ConsultationSessionContainer")
);
const PaymentComplete = React.lazy(() =>
  import("./Components/PaymentComplete")
);
const ProfileContainer = React.lazy(() =>
  import("./Components/screens/profil/ProfileContainer")
);
const ProgrammeInfo = React.lazy(() =>
  import("./Components/screens/coach/ProgrammeInfo")
);
const Schedule = React.lazy(() => import("./Components/widgets/Schedule"));

/*
const CoachContainer = React.lazy(() => import("./Components/CoachContainer"));
*/

const { company } = session();
const modules = [
  {
    name: "div1",
    label: "Dashboard",
    divider: true,
    icon: "i-Bar-Chart-2",
    access: [
      "company_admin",
      "group_admin",
      "coach",
      "company_member",
    ],
    children:[
      {
        name: "What's New?",
        label: "Home",
        slug: "home",
        icon: "i-Home1",
        component: CoachingLanding,
        external:true,
      },
      {
        name: "Performance",
        slug: "summary",
        crud: false,
        icon: "i-Support",
        component: DashboardContainer,
      },
      {
        name: "Team Dashboard",
        slug: "team-dashboard",
        crud: false,
        icon: "i-Support",
        component: TeamDashboardContainer,
        visible: false,
      },
      {
        name: "Member Dashboard",
        slug: "member",
        crud: false,
        icon: "i-Support",
        component: MemberDashboardContainer,
        visible: false,
      },
      {
        name: "Book Consultation",
        slug: "book",
        crud: false,
        icon: "i-Support",
        component: BookConsultationContainer,
        visible: false,
      },
      {
        name: "Consultation Session",
        slug: "session",
        crud: false,
        icon: "i-Support",
        component: ConsultationSessionContainer,
        visible: false,
      },
    ],
  },
  
  {
    name: "div1c",
    label: "Dashboard",
    divider: true,
    icon: "i-Bar-Chart-2",
    access: [
      "superadmin"
    ],
    children:[
      {
        name: "Performance",
        slug: "summary",
        crud: false,
        icon: "i-Support",
        component: DashboardContainer,
      },
    ],
  },
  
  {
    name: "div5",
    label: "Coach Dashboard",
    divider: true,
    icon: "i-Billing",
    access: ["coach"],
    children:[
        {
          name: "Coach Profile",
          slug: "profile",
          icon: "i-Book",
          singularName: "profile",
          crud: false,
          component: ProfileContainer,
          access: ["coach"],
          updateLabel: "klaster",
          meta: {
            delete_flag: true,
          },
          actions: [
            {
              icon: "add",
              label: "Detil Data",
              component: "klaster_detail",
            },
          ],
          fields: [
            {
              label: "Klaster",
              name: "klaster",
              type: "text",
            },
          ],
        },
        {
          name: "Programmes",
          slug: "programmes",
          icon: "i-Calendar-4",
          singularName: "programme",
          crud: true,
          endpoint: "/programme",
          updateLabel: "name",
          //node:"programmes",
          access: ["coach"],
          meta: {
            delete_flag: true,
          },
          actions: [
            {
              icon: "i-Gear-2",
              label: "Quest Setup",
              component: "dashboard/programme-quest",
            },
            {
              icon: "i-Business-Mens",
              label: "Subscribers",
              component: "dashboard/programme-subscribers",
            },
            {
              icon: "i-Financial",
              label: "Transactions History",
              component: "dashboard/programme-transactions",
            },
            {
              icon: "i-Line-Chart",
              label: "Report",
              component: "dashboard/programme-report",
            },
          ],
          fields: [
            {
              label: "Name",
              name: "name",
              type: "text",
            },
            {
              label: "Description",
              name: "description",
              type: "richtext",
              visible: false,
            },
            {
              label: "Quest Complete Text",
              name: "complete_text",
              type: "text",
              visible: false,
            },
            {
              label: "Price",
              name: "price",
              type: "number",
            },
            {
              label: "Audience",
              name: "company_id",
              hint: "leave it if public",
              visible: true,
              type: "lookup",
              resource: "crud/companies",
              key: "id",
              searchable: true,
              defaultValue: (data) => {
                if (typeof data === "undefined") return;
                if (data === null) return;
                return {
                  label: data.name,
                  value: data.id,
                };
              },
              value: (data) => {
                if (!data) return;
                return {
                  label: data.name,
                  value: data.id,
                  text: data.name,
                };
              },
              resource_label: (row) => {
                if (row.company_id === 0) return "Public";
                //else return row.companies.name;
                if (typeof row.companies !== "undefined") return row.companies.name;
                return "Exclusive";
              },
            },
            {
              label: "Video Youtube",
              name: "video_url",
              type: "text",
              visible: false,
              hint:'Please input youtube URL',
            },
            {
                label: "Thumbnail Image",
                name: "thumbnail_url",
                type: "image",
                formatValue: (data)=>{
                  return data.original;
                }
              },
            {
              label: "Subscribers",
              name: "subscribers",
              type: "number",
              input:false,
              resource_label: (row) => row.subscribers.total,
            },
            {
              label: "Quest Scoring",
              name: "auto_evaluation",
              type: "lookup",
              values: [
                { text: "Manual Assignment", value: 0 },
                { text: "Automatic", value: 1 },
                
              ],
              resource_label: (row) => {
                let statuses = ["Draft", "Published", "Unpublished"];
                return statuses[parseInt(row.n_status)];
              },
              defaultValue: (data) => {
                if (typeof data === "undefined") return;
                if (data === null) return;

                try {
               
                  return data.n_status;
                } catch (error) {
                  return null;
                }
              },
            },
            {
              label: "Programme Status",
              name: "n_status",
              type: "lookup",
              values: [
                { text: "Draft", value: 0 },
                { text: "Published", value: 1 },
                { text: "Unpublished", value: 2 },
              ],
              resource_label: (row) => {
                let statuses = ["Draft", "Published", "Unpublished"];
                return statuses[parseInt(row.n_status)];
              },
              defaultValue: (data) => {
                if (typeof data === "undefined") return;
                if (data === null) return;

                try {
               
                  return data.n_status;
                } catch (error) {
                  return null;
                }
              },
            },
          ],
        },
        {
          name: "Quests",
          slug: "programme-quest",
          icon: "i-Gear-2",
          singularName: "quest",

          crud: true,
          endpoint: "/programme/quests",
          updateLabel: "name",

          meta: {
            delete_flag: true,
          },
          actions: [],
          access: ["coach"],
          backTo: "dashboard/programmes",
          visible: false,
          parentFilters: [
            {
              field: "programme_id",
              value: (data) => {
                return data.id;
              },
            },
          ], //default query filter
          addBefore: [ProgrammeInfo], //add component before content
          fields: [
            {
              label: "Programme",
              name: "programme_id",
              type: "auto",
              resource: "programme",
              key: "id",
              resource_label: (row) => {
                return row.programme_name;
              },
              text: (data) => {
                if (!data) return;
                return data.name;
              },
              value: (data) => {
                if (!data) return;
                return data.id;
              },
              visible: false,
            },
            {
              label: "Prequisite Quest",
              name: "prequisite_task_id",
              type: "lookup",
              resource: "programme/quests",
              key: "task_id",
              resource_label: (row) => {
                return row.prequisite_task_id;
              },
              value: (data) => {
                return data.task_name;
              },
              visible: false,
            },
            {
              label: "Quest No.",
              name: "quest_no",
              type: "number",
            },

            {
              label: "Name",
              name: "name",
              type: "text",
              resource_label: (row) => {
                return row.task_name;
              },
              value: (data) => {
                
                return data.task_name;
              },
            },
            {
              label: "Points Rewarded",
              name: "points",
              type: "number",
              resource_label: (row) => {
                return row.task_points;
              },
              value: (row) => {
                return row.task_points;
              },
            },
            {
              label: "Description",
              name: "description",
              type: "richtext",
              resource_label: (row) => {
                return row.task_desc;
              },
              value: (row) => {
                return row.task_desc;
              },
              visible: false,
            },
            {
              label: "Requirement Item",
              name: "requirements",
              type: "text",
              visible: false,
            },
            {
              label: "Total Requirement",
              name: "total_preq",
              type: "number",
              visible: false,
            },
            {
              label: "Start Time",
              name: "start_time",
              type: "date",
              visible: false,
            },
            {
              label: "Durations (days)",
              name: "durations",
              type: "number",
            },
            {
              label: "Video URL / Youtube URL",
              name: "video_url",
              type: "text",
              visible: false,
            },
          ],
        },
        {
          name: "Subscribers",
          slug: "programme-subscribers",
          icon: "i-Gear-2",
          singularName: "subcriber",
          crud: true,
          endpoint: (props) => {
            
            return `/programme/${props.id}/subscribers`;
          },
          updateLabel: "Subscriber",
          update: false,
          edit: false,
          add: false,
          delete: false,
          access: ["coach"],
          backTo: "dashboard/programmes",
          visible: false,
          parentFilters: [
            {
              field: "programme_id",
              value: (data) => {
                return data.id;
              },
            },
          ], //default query filter
          addBefore: [ProgrammeInfo], //add component before content
          meta: {
            delete_flag: true,
          },
          actions: [],
          fields: [
            {
              label: "Name",
              name: "account_name",
              type: "text",
            },
            {
              label: "Email",
              name: "account_email",
              type: "text",
            },
            {
              label: "Phone",
              name: "account_phone",
              type: "text",
            },
            {
              label: "Start",
              name: "created_at",
              type: "text",
              resource_label: (row) => {
                return moment(row.created_at).format("DD/MM/YYYY");
              },
            },
            {
              label: "Expired",
              name: "valid_until",
              type: "text",
              resource_label: (row) => {
                return row.valid_until;
                //return moment(row.valid_until).format("DD/MM/YYYY")
              },
            },
          ],
        },
        {
          name: "Programme Reports",
          slug: "programme-report",
          icon: "i-Gear-2",
          singularName: "transactions",
          crud: true,
          endpoint: "/programme/transactions",
          updateLabel: "Transaction",
          backTo: "dashboard/programmes",
          visible: false,
          parentFilters: [
            {
              field: "programme_id",
              value: (data) => {
                return data.id;
              },
            },
          ], //default query filter
          addBefore: [ProgrammeInfo], //add component before content
          access: ["coach"],
          update: false,
          add: false,
          delete: false,
          meta: {
            delete_flag: true,
          },
          actions: [],
          fields: [
            {
              label: "Klaster",
              name: "klaster",
              type: "text",
            },
          ],
        },
        {
          name: "Transaction",
          slug: "programme-transactions",
          icon: "i-Gear-2",
          singularName: "transactions",
          crud: true,
          endpoint: (props) => {
            return `/programme/${props.id}/transactions`;
          },
          updateLabel: "Transaction",
          backTo: "dashboard/programmes",
          visible: false,
          parentFilters: [
            {
              field: "programme_id",
              value: (data) => {
                return data.id;
              },
            },
          ], //default query filter
          addBefore: [ProgrammeInfo], //add component before content
          access: ["coach"],
          update: false,
          add: false,
          delete: false,
          meta: {
            delete_flag: true,
          },
          actions: [],
          fields: [
            {
              label: "Date",
              name: "invoice_date",
              type: "text",
              resource_label: (row) => {
                return moment(row.invoice_date).format("DD/MM/YYYY");
              },
            },
            {
              label: "No.Invoice",
              name: "nomor_invoice",
              type: "text",
            },
            {
              label: "Name",
              name: "account_name",
              type: "text",
            },
            {
              label: "Email",
              name: "account_email",
              type: "text",
            },
            {
              label: "Phone",
              name: "account_phone",
              type: "text",
            },
            {
              label: "Status",
              name: "paid_status",
              type: "text",
              resource_label: (row) => {
                if (row.paid_status === 1) return "Paid";
                if (row.paid_status > 1) return "Failed";
                return "Pending";
              },
            },
          ],
        },
        /**coach consultation management */
        {
          name: "Consultation",
          slug: "coach-consultations",
          icon: "i-Speach-Bubbles",
          singularName: "consultation",
          crud: true,
          endpoint: "/paket/konsultasi/list",
          insertEndpoint: "/paket/konsultasi/create",
          updateEndpoint: (props) => {
            return `/paket/konsultasi/update`;
          },
          updateLabel: "name",
          access: ["coach"],

          meta: {
            delete_flag: true,
          },
          actions: [
            {
              icon: "i-Financial",
              label: "Transactions History",
              component: "dashboard/consultation-history",
            },
            {
              icon: "i-Calendar",
              label: "Schedule",
              component: "dashboard/consultation-agenda",
            },
          ],
          fields: [
            {
              label: "Name",
              name: "name",
              type: "text",
            },
            {
              label: "Description",
              name: "description",
              type: "richtext",
              visible: false,
            },

            {
              label: "Price",
              name: "harga",
              type: "number",
              resource_label: (row) => {
                return row.harga;
              },
            },
            {
              label: "Promo Picture",
              name: "paket_image_url",
              type: "image",
            },
            {
              label: "Session Length",
              name: "time_limit",
              type: "lookup",
              values: [
                {
                  text: "30 menit",
                  value: 30 * 60,
                },
                {
                  text: "1 Jam",
                  value: 60 * 60,
                },
                {
                  text: "2 Jam",
                  value: 120 * 60,
                },
                {
                  text: "3 Jam",
                  value: 180 * 60,
                },
              ],
              resource_label: (row) => {
                return Math.ceil(parseInt(row.time_limit) / 60);
              },
              defaultValue: (data) => {
                
                if (typeof data === "undefined") return 30 * 60;
                if (data === null) return 30 * 60;
                return data.time_limit;
              },
            },
          ],
        },
        {
          name: "Session Schedules",
          slug: "consultation-schedule",
          icon: "i-Clock",
          singularName: "subscriber",
          crud: true,
          endpoint: `/coach/konsultasi/list`,
          updateLabel: "name",
          update: false,
          edit: false,
          add: false,
          delete: false,
          access: ["coach"],
          // parentFilters: [
          //   {
          //     field: "programme_id",
          //     value: (data) => {
          //       return data.id;
          //     },
          //   },
          // ], //default query filter
          // addBefore: [ProgrammeInfo], //add component before content
          meta: {
            delete_flag: true,
          },
          actions: [
            {
              icon: "i-Play-Music",
              label: "Start Session",

              uri: (props) => `/konsultasi/accept/${props.id}`,
              trigger: (props) => {
                if (props.status === "ready") return true;
              },
            },
            {
              icon: "i-Stop-Music",
              label: "Close Session",
              uri: (props) => `/chat/sesi/${props.id}/end`,
              trigger: (props) => {
                if (props.status === "ongoing") return true;
              },
            },
            {
              icon: "i-Speach-Bubbles",
              label: "Go To Session",
              openWindow: true,
              component: false,
              redirect: true,
              trigger: (props) => {
                if (props.status === "ongoing") return true;
              },
              uri: (props) => {
                
                return `/session/chat/${props.id}`;
              },
            },
          ],
          fields: [
            {
              label: "Client",
              name: "account_name",
              type: "text",
            },
            {
              label: "Session Length",
              name: "time_limit",
              type: "text",
              resource_label: (row) => {
                return Math.ceil(row.time_limit / 60) + " Minutes";
              },
            },
            {
              label: "When",
              name: "hari",
              type: "text",
            },
            {
              label: "Time",
              name: "jam",
              type: "text",
              resource_label: (row) => {
                return row.jam + " WIB";
              },
            },
          ],
        },
        {
          name: "Schedule",
          slug: "consultation-agenda",
          icon: "i-Clock",
          singularName: "subscriber",
          visible:false,
          crud: true,
          endpoint: (data) => `/paket/konsultasi/${data.id}/jadwal`,
          insertEndpoint: (data) => {
            return `/jadwal/paket/create`;
          },
          updateEndpoint: (data) => {
            return `/jadwal/paket/${data.id}/update`;
          },
          deleteEndpoint: (data) => {
            return `/jadwal/paket/remove`;
          },

          backTo: "dashboard/coach-consultations",
          updateLabel: "name",
          update: false,
          edit: false,
          add: true,
          delete: true,
          access: ["coach"],
          parentFilters: [
            {
              field: "paket_id",
              value: (data) => {
                return data.id;
              },
            },
          ], //default query filter
          // addBefore: [ProgrammeInfo], //add component before content
          meta: {
            delete_flag: true,
          },
          actions: [],
          fields: [
            {
              label: "Consultation",
              name: "paket_id",
              type: "auto",
              text: (data) => {
             
                if (!data) return;
                return data.id;
              },
              value: (data) => {
              
                if (!data) return;
                return data.id;
              },
              visible: false,
            },

            {
              label: "When",
              name: "when",
              type: "custom",
              component: Schedule,
             
              resource_label: (row) => {
                return row.hari + " " + row.jam;
              },
            },

            {
              label: "Keterangan",
              name: "keterangan",
              type: "text",
            },
            {
              label: "Meeting URL",
              name: "meeting_url",
              type: "text",
              hint: "Link ZOOM, Google Meet, dsb",
            },
          ],
        },
        /** coach consultation management */
        {
          name: "Tutorials",
          slug: "tutorials",
          visible: false,
          icon: "i-Gear-2",
          singularName: "tutorials",
          crud: true,
          endpoint: "/tutorials",
          updateLabel: "Tutorial",
          update: false,
          add: false,
          delete: false,
          meta: {
            delete_flag: true,
          },
          actions: [],
          fields: [
            {
              label: "Klaster",
              name: "klaster",
              type: "text",
            },
          ],
        },
    ],
  },

  
  {
    name: "div1",
    label: "Assignments",
    divider: true,
    icon: "i-Clock-Forward",
    access: [
      "company_admin",
      "group_admin",
      "coach",
      "company_member",
    ],
    children:[
          {
          name: "Teams",
          slug: "teams",
          crud: false,
          icon: "i-Business-Mens",
          component: TeamsContainer,
          access: ["superadmin", "admin", "company_admin", "group_admin"],
          fields: [
            {
              label: "Name",
              name: "name",
              type: "text",
              required: true,
            },
            {
              label: "Description",
              name: "description",
              type: "textarea",
              required: true,
            },
            {
              label: "Team Leader / Group Admin",
              name: "admins",
              type: "lookup",
              searchable: true,
              multiple: true,
              resource: () => {
                if (typeof company === "undefined") return "";
                if (company === null) return "";
                return "/companies/" + company.id + "/members";
              },
              //resource: "companies/" + company.id + "/members",
              resourceNode: "members",
              key: "id",
              valueField: "name",
              required: true,
              resource_label: (data) => {
                return "-";
              },
              defaultValue: (data) => {
                if (typeof data === "undefined") return;
                if (data === null) return;
                if (typeof data.admins === "undefined") return;
                if (data.admins === null) return;
                if (data.admins === "") return;
               
                try {
                  let d = data.admins.map((tt) => {
                    return {
                      label: tt.name,
                      value: tt.id,
                    };
                  });
                  return d;
                } catch (error) {
                  return null;
                }
              },
              value: (data) => {
                if (typeof data === "undefined") return;
                if (data === null) return;

                return {
                  label: data.name,
                  value: data.id,
                };
              },
              visible: false,
            },
            {
              label: "Members",
              name: "members",
              type: "lookup",
              searchable: true,
              multiple: true,
              //resource: "companies/" + company.id + "/members",
              resource: () => {
                if (typeof company === "undefined") return "";
                if (company === null) return "";
                return "/companies/" + company.id + "/members";
              },
              resourceNode: "members",
              key: "id",
              valueField: "name",
              //required: true,
              resource_label: (data) => {
                let _arr = data.members;
                let a = [];
                _arr.map((t) => {
                  a.push(t.name);
                });
                return a.join(",");
              },
              defaultValue: (data) => {
                if (typeof data === "undefined") return;
                if (data === null) return;
                if (typeof data.members === "undefined") return;
                if (data.members === null) return;
                if (data.members === "") return;

                try {
                  let d = data.members.map((tt) => {
                    return {
                      label: tt.name,
                      value: tt.id,
                    };
                  });
                  return d;
                } catch (error) {
                  return null;
                }
              },
              value: (data) => {
                if (typeof data === "undefined") return;
                if (data === null) return;

                return {
                  label: data.name,
                  value: data.id,
                };
              },
              visible: false,
            },
            {
              label:'or you can import member list from excel file',
              name:'import',
              type:'custom',
              component:ImportMember,
            }
          ],
        },

        {
          name: "Tasks",
          slug: "quests",
          crud: false,
          icon: "i-Files",
          component: QuestsContainer,
        },

        {
          name: "Workspace",
          slug: "workspace",
          crud: false,
          icon: "i-ID-3",
          component: WorkspaceContainer,
        },

        {
          name: "New Task",
          slug: "create-quest",
          crud: false,
          icon: "i-Add-File",
          component: CreateTaskContainer,
          intro: "Please fill all the fields below",
          visible: false,
        },

        {
          name: "Delegate Task",
          slug: "delegate-quest",
          crud: false,
          icon: "i-Support",
          component: DelegateTaskContainer,
          intro: "Please fill all the fields below",
          visible: false,
        },
    ],
  },

  

  {
    name: "div5",
    label: "Coaching Programme",
    divider: true,
    icon: "i-Support",
    access: [ "superadmin"],
    children:[
    {
            name: "Coaches",
            slug: "coaches",
            singularName: "Coach",
            crud: true,
            endpoint: '/coach',
            updateLabel: "name",
            meta: {
              delete_flag: true,
            },
            access:["superadmin"],
            delete: true,
            add: true,
            update: true,
            edit: true,
            actions:[],
            fields: [
              {
                label: "Name",
                name: "photo_url",
                type: "image",
                formatValue: (data)=>{
                  
                  return data.original;
                }
              },
              {
                label: "Account",
                name: "account_id",
                type: "lookup",
                searchable: true,
                
                resource: ()=>{
                   return "/crud/accounts"
                },
                //resource: "companies/" + company.id + "/members",
                resourceNode: "data",
                key: "id",
                valueField: "name",
                required: true,
                resource_label: (data) => {
                  
                  return data.accounts.name;
                 
                },
                defaultValue: (data) => {
                  
                  if (typeof data === "undefined") return;
                  if (data === null) return;
                  if (typeof data.accounts === "undefined") return;
                  if (data.accounts === null) return;
                  if (data.accounts === "") return;
                  
                  return {
                    label: data.accounts.name + ' - ' + data.accounts.email,
                    value: data.account_id,
                  };
                },
                value: (data) => {
                  if (typeof data === "undefined") return;
                  if (data === null) return;

                  return {
                    label: data.name + ' - ' + data.email,
                    value: data.id,
                  };
                },
                visible: false,
              },
              {
                label: "Name",
                name: "name",
                type: "text",
              },
              
              {
                label: "Profession",
                name: "profession",
                type: "text",
               
              },
            {
                label: "Description",
                name: "description",
                type: "richtext",
                visible:false,
               
              },
             
              
            ],
          },
          {
            name: "Programmes",
            slug: "coaching-programmes",
            singularName: "Programmes",
            crud: true,
            endpoint: '/coaching-programme',
            updateLabel: "name",
            meta: {
              delete_flag: true,
            },
            access:["superadmin"],
            delete: false,
            add: false,
            update: false,
            edit: false,
            actions:[
                  {
                  icon: "i-File-Clipboard-File--Text",
                  label: "Copy Link",
                  onClick:(item, onActionDone)=>{
                    navigator.clipboard.writeText(item.share_link);
                    onActionDone((parent)=>{
                      console.log("action done", item.share_link);
                        parent.setState({
                          snackbar:true,
                          snackbarText:"Link has been copied to clipboard !"
                        })
                    })
                  }
                },
              ],
            fields: [
              {
                label: "Image",
                name: "thumbnail_url",
                type: "image",
                formatValue: (data)=>{
                  
                  return data.original;
                }
              },
              
              {
                label: "Name",
                name: "name",
                type: "text",
              },
              
              {
                label: "Coach",
                name: "coach",
                type: "text",
                resource_label:(row)=>{
                  if(!row.coach) return '';
                  return row.coach.name;
                }
               
              },
              {
                label: "Subscribers",
                name: "subscribers",
                type: "text",
                resource_label:(row)=>{
                  if(!row) return 0;
                  return row.subscribers.total;
                }
              },
              {
                label: "Gross",
                name: "gross_revenue",
                type: "text",
                resource_label:(row)=>{
                  if(!row) return 0;
                  return accounting.formatNumber(parseInt(row.subscribers.total) * parseInt(row.price),0,'.');
                }
              },
              
              {
                label: "Status",
                name: "status",
                type: "text",
                resource_label:(row)=>{
                  const status = ['Draft','Running','Closed'];
                  if(!row) return 0;
                  return status[parseInt(row.n_status)];
                }
              },
              
              
            ],
          },
          {
          name: "Internal Events",
          slug: "internal-events",
        
          singularName: "Internal Event",
          crud: true,
          endpoint: "/internal-landing",
          updateLabel: "Internal Event",
           access:["superadmin"],
          update: true,
          add: true,
          delete: true,
          meta: {
            delete_flag: true,
          },
          actions: [],
          fields: [
            {
              label: "Title",
              name: "title",
              type: "text",
            },
            {
              label: "Coach Name",
              name: "coach_name",
              type: "text",
            },
            {
              label: "Photo",
              name: "foto_url",
              type: "image",
              formatValue: (data)=>{
                  return data.original;
                }
            },
            {
              label: "Short Description",
              name: "short_desc",
              type: "text",
            },
            {
              label: "Long Description",
              name: "long_desc",
              type: "richtext",
            },
            {
              label: "URL",
              name: "url",
              type: "text",
            },
          ],
        },
        {
          name: "Programmes",
          slug: "coaching-programmes",
          access: ["company_admin"],
          crud: false,
          icon: "i-Library",
          component: CoachingProgrammeContainer,
        },

        {
          name: "Consultation",
          slug: "coaching-consultation",
          crud: false,
          icon: "i-Speach-Bubble-13",
           access: ["company_admin",
            "group_admin",
            "coach",
            "company_member"],
          component: ConsultationContainer,
        },
    ],
  },
  {
    name: "div5c",
    label: "Coaching Programme",
    divider: true,
    icon: "i-Support",
    access: [ "company_admin"],
    children:[
   
        {
          name: "Programmes",
          slug: "available-programmes",
          access: ["company_admin"],
          crud: false,
          icon: "i-Library",
          component: CoachingProgrammeContainer,
        },

        {
          name: "Consultation",
          slug: "coaching-consultation",
          crud: false,
          icon: "i-Speach-Bubble-13",
           access: ["company_admin",
            "group_admin",
            "coach",
            "company_member"],
          component: ConsultationContainer,
        },
    ],
  },
  
  {
    name: "div6",
    label: "Rewards",
    divider: true,
    icon: "i-Gift-Box",
    access: [ "admin", "company_admin"],
    children: [
      {
          name: "Rewards",
          slug: "rewards",
          crud: false,
          icon: "i-Gift-Box",
          access: ["superadmin", "admin", "company_admin"],
          component: RewardContainer,
          fields: [
            {
              label: "Name",
              name: "name",
              type: "text",
              required: true,
            },
            {
              label: "Description",
              name: "description",
              type: "textarea",
              required: true,
            },
            {
              label: "Points",
              name: "points",
              type: "number",
              required: true,
            },
            {
              label: "Stocks",
              name: "stock",
              type: "number",
              required: true,
            },
            {
              label: "Photo",
              name: "picture",
              type: "image",
              sizes: {
                hp: "hp_file",
                thumb: "thumbnail_file",
                original: "picture",
              },
              required: true,
            },
          ],
        },
    ]
  },
  

  {
    name: "div5",
    label: "Accounts & Billings",
    divider: true,
    icon: "i-Billing",
     access: ["superadmin", "admin", "company_admin"],
      children:[
       {
            name: "Account & Subscription",
            slug: "my-account",
            crud: false,
            icon: "i-Support",
            access:["company_admin",
              "group_admin",
              "coach",
              "company_member"],
            component: MyAccount,
           
          },
        {
            name: "User",
            slug: "users",
            crud: false,
            icon: "i-Business-Man",
            component: UserContainer,
            access: ["company_admin"],
            fields: [
              {
                label: "Name",
                name: "name",
                type: "text",
              },
              {
                label: "Email",
                name: "email",
                type: "text",
              },

              {
                label: "Phone",
                name: "phone_number",
                type: "text",
              },
              {
                label: "Password",
                name: "password",
                type: "text",
              },
            ],
          },
          {
            name: "User Management",
            slug: "users-mgmt",
            crud: false,
            icon: "i-Business-Man",
            component: UserMgmtContainer,
            access: ["superadmin","admin"],
            filters: [
              {
                name: "Organization",
                resource: "crud/companies",
                parameterName: "company_id",
                key: "id",
                access: [
                  "superadmin",
                  "admin"
                ],
                value: (data) => {
                  return {
                    label: data.name,
                    value: data.id,
                  };
                },
              },
            ],
            fields: [
              {
                label: "Name",
                name: "name",
                type: "text",
              },
              {
                label: "Email",
                name: "email",
                type: "text",
              },

              {
                label: "Phone",
                name: "phone_number",
                type: "text",
              },
              {
                label: "Password",
                name: "password",
                type: "text",
              },
            ],
          },

          {
            name: "Subscription",
            slug: "plans",
            crud: false,
            icon: "i-Support",
            component: SubscriptionContainer,
            access: [ "company_admin"],
          },

          {
                name: "Subscription Plans",
                slug: "plans-setup",
                singularName: "Plan",
                crud: true,
                endpoint: '/subscription_plans',
                updateLabel: "name",
                meta: {
                  delete_flag: true,
                },
                access:['superadmin'],
                delete: true,
                add: true,
                update: true,
                edit: true,
                actions:[],
                fields: [
                  {
                    label: "Name",
                    name: "name",
                    type: "text",
                  },
                  {
                    label: "Description",
                    name: "description",
                    type: "richtext",
                  },
                  {
                    label: "Member Limit",
                    name: "members",
                    type: "number",
                    
                  },
                  {
                    label: "Group Admin Limit",
                    name: "group_admins",
                    type: "number",
                    
                  },
                  {
                    label: "Group Limit",
                    name: "group_limits",
                    type: "number",
                   
                  },
                  {
                    label: "Base Storage Limit",
                    name: "storage_limit",
                    type: "number",
                   
                   
                  },

                  {
                    label: "Monthly Price",
                    name: "monthly_price",
                    type: "number",
                  },
                  {
                    label: "Yearly Price",
                    name: "yearly_price",
                    type: "number",
                  },
                  
                ],
              },
    ],
  },
 
  
  {
    name: "chat-session",
    slug: "chat/:id",
    crud: false,
    icon: "i-Support",
    visible: false,
    component: ChatSession,
  },
  /*{
    name: "Help & Support",
    slug: "help",
    icon: "i-Gear-2",
    singularName: "help",
    crud: true,
    endpoint: "/help",
    updateLabel: "help",
    update: false,
    add: false,
    delete: false,
    meta: {
      delete_flag: true,
    },
    actions: [],
    fields: [
      {
        label: "Klaster",
        name: "klaster",
        type: "text",
      },
    ],
  },*/

  {
    name: "div6",
    label: "Koolino",
    divider: true,
    icon: "i-Gear-2",
    access: ["superadmin", "admin"],
    children:[
          {
            name: "Koolino Banner",
            slug: "koolino-banners",
            crud: true,
            icon: "i-Business-Man",
            endpoint: '/crud/koolino_banners',
            access: ["superadmin","admin"],
            fields: [
              {
                label: "Name",
                name: "name",
                type: "text",
              },
              
              
              {
                label: "Link URL",
                name: "link_url",
                type: "text",
              },
             
              {
                label: "Banner Image",
                name: "image_url",
                type: "image",
                formatValue: (data)=>{
                  return data.original;
                }
              },
              
              {
                label: "Publish Status",
                name: "publish",
                type: "lookup",
                values:["Unpublished","Published"]
              },
            ],
          },
          {
            name: "Koolino Highlights",
            slug: "koolino-highlights",
            crud: true,
            icon: "i-Business-Man",
            endpoint: '/crud/koolino_highlights',
            access: ["superadmin","admin"],
            fields: [
              {
                label: "Name",
                name: "name",
                type: "text",
              },
              
              
              {
                label: "Link URL",
                name: "link_url",
                type: "text",
              },
             
              {
                label: "Banner Image",
                name: "image_url",
                type: "image",
                formatValue: (data)=>{
                  return data.original;
                }
              },
              
              {
                label: "Publish Status",
                name: "publish",
                type: "lookup",
                values:["Unpublished","Published"]
              },
            ],
          },
          {
            name: "Koolino Programmes",
            slug: "koolino-programmes",
            crud: true,
            icon: "i-Business-Man",
            endpoint: '/crud/koolino_programmes',
            access: ["superadmin","admin"],
            fields: [
              {
                label: "Name",
                name: "name",
                type: "text",
              },
              
              {
                label: "Description",
                name: "description",
                type: "richtext",
              },
              {
                label: "Link URL",
                name: "link_url",
                type: "text",
              },
              {
                label: "Programme Photo",
                name: "image_url",
                type: "image",
                formatValue: (data)=>{
                  return data.original;
                }
              },
              {
                label: "Coach Photo",
                name: "coach_photo_url",
                type: "image",
                formatValue: (data)=>{
                  return data.original;
                }
              },
              {
                label: "Coach Name",
                name: "coach_name",
                type: "text",
              },
              {
                label: "About the Coach",
                name: "coach_title",
                type: "text",
              },
              {
                label: "Publish Status",
                name: "publish",
                type: "lookup",
                values:["Unpublished","Published"],
                resource_label:(row)=>row.publish
              },
            ],
          },

              
      ]
  },
  
  // {
  //   name: "Help & Support",
  //   slug: "help",
  //   icon: "i-Gear-2",
  //   singularName: "help",
  //   crud: true,
  //   endpoint: "/help",
  //   updateLabel: "help",
  //   update: false,
  //   add: false,
  //   delete: false,
  //   meta: {
  //     delete_flag: true,
  //   },
  //   actions: [],
  //   fields: [
  //     {
  //       label: "Klaster",
  //       name: "klaster",
  //       type: "text",
  //     },
  //   ],
  // },
 
  
  /*
  {
    name: "Return",
    slug: "return",
    crud: false,
    icon: "i-Support",
    component: ReturnFromPayment,
    visible: false,
  },
  {
    name: "PaymentComplete",
    slug: "success",
    crud: false,
    icon: "i-Support",
    component: PaymentComplete,
    visible: false,
  },*/
];
export default modules;
